<template>
  <div>
    <van-cell
      title="商品管理"
      is-link
      @click="$router.push({ path: 'PageProductMgr' })"
    />
    <van-cell
      title="类型管理"
      is-link
      @click="$router.push({ path: 'ProductTypesMgr' })"
    />
  </div>
</template>

<script>
import { getToken, setToken } from "@/utils/LoginMgr";

export default {
  name: "PageMgrCenter",
  created() {
    let token = this.$route.query.token;
    if (token != null && token !== "") {
      setToken(token);
    }
    this.token = getToken();
  }
};
</script>

<style scoped></style>
