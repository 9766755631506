import { Toast } from "vant";
import qs from "qs";
import { showLoading, dismissLoading } from "@/components/LoadingMgr";
import { getToken } from "@/utils/LoginMgr";

const axios = require("axios");

const http = axios.create({
  // baseURL: 'https://some-domain.com/api/',
  timeout: 20000
  // headers: { "X-Custom-Header": "foobar" }
});
http.interceptors.request.use(
  function(config) {
    // todo token拦截器
    let token = getToken();
    config.headers["YT-SHOP-RIDER-TOKEN"] = token;
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export function post(url, data = {}, headers = {}, showLoading = true) {
  return fetch(url, "post", data, null, headers, showLoading);
}

export function postForm(url, data = {}, headers = {}, showLoading = true) {
  return fetch(url, "post", data, null, headers, showLoading, true);
}

export function get(url, params = {}, headers = {}, showLoading = true) {
  return fetch(url, "get", null, params, headers, showLoading);
}

export function postFile(url, file = null, headers = {}, showLoading = true) {
  return fetch(url, "post", file, null, headers, showLoading, false, true);
}

async function fetch(
  url,
  method = "post",
  data,
  params,
  headers = {},
  needShowLoading = true,
  isFormData = false,
  isFile = false
) {
  if (isFormData) {
    headers["content-type"] = "application/x-www-form-urlencoded";
  } else if (isFile) {
    headers["content-type"] = "multipart/form-data";
  }

  let uploadData = data;
  if (isFormData) {
    uploadData = qs.stringify(data);
  } else if (isFile) {
    uploadData = new FormData(); //创建form对象
    uploadData.append("file", data);
  }

  if (needShowLoading) {
    showLoading();
  }

  let resp;

  try {
    resp = await http({
      method: method,
      url: url,
      headers: headers,
      data: uploadData,
      params: params
    });
  } catch (e) {
    console.log(e);
    Toast(e);
    return Promise.reject(e);
  } finally {
    if (needShowLoading) {
      dismissLoading();
    }
  }
  if (resp.status === 200) {
    // todo 处理业务级状态码时 resp.data["你的业务状态码"] 并返回Promise.resolve(resp.data["你的业务字段"])；
    if (resp.data.errno === 0) {
      return Promise.resolve(resp.data.data);
    } else {
      Toast(resp.data.errmsg);
      return Promise.reject(resp.data.errmsg);
    }
  } else {
    Toast(resp.status);
    return Promise.reject(resp.status);
  }
}
