import { ENV, PUBLISHING_ENVIRONMENT } from "@/main";

const _DEV_API_HOST = "http://192.168.1.53:8076";
const _TEST_API_HOST = "http://47.105.49.8:8076";
const _PRO_API_HOST = "https://shop.api.linkyou.ink";

export function getUrl(url) {
  let baseUrl = "";
  switch (ENV) {
    case PUBLISHING_ENVIRONMENT.DEV:
      baseUrl += _DEV_API_HOST;
      break;
    case PUBLISHING_ENVIRONMENT.TEST:
      baseUrl += _TEST_API_HOST;
      break;
    case PUBLISHING_ENVIRONMENT.PROD:
      baseUrl += _PRO_API_HOST;
      break;
  }
  return baseUrl + url;
}

export const getProductTypes = "/rs/shop/goodsTypelist";
export const addProductType = "/rs/shop/goodsTypecreate";
export const delProductType = "/rs/shop/goodsTypedelete";
export const editProductType = "/rs/shop/goodsTypeupdate";
export const getGoodsTypes = "/rs/shop/typeList";
export const getGoodsList = "/rs/shop/productList";
export const delGood = "/rs/shop/delete";
export const setBatchGoodsState = "/rs/shop/batchupdate";
export const setGoodState = "/rs/shop/updatebyid";
export const getGoodDetail = "/rs/shop/detail";
export const getCateAndBrand = "/rs/shop/catAndBrand";
export const uploadPic = "/rs/shop/storage";
export const addProduct = "/rs/shop/create";
export const editProduct = "/rs/shop/update";
