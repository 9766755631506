const TOKEN_NAME = "TOKEN";

export function setToken(token = "") {
  localStorage.setItem(TOKEN_NAME, token);
}

export function getToken() {
  let token = localStorage.getItem(TOKEN_NAME);
  if (token === null) {
    return "";
  }
  return token;
}
