import Vant from "vant";
import "vant/lib/index.css";
import "./assets/tailwind.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vconsole from "vconsole";
import VueBus from "vue-bus";
import { get, post, postForm, postFile } from "@/net/axiosMgr";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import VueQuillEditor from "vue-quill-editor";

Vue.use(VueQuillEditor);

export const PUBLISHING_ENVIRONMENT = {
  DEV: 0,
  TEST: 1,
  PROD: 2
};
export const ENV = PUBLISHING_ENVIRONMENT.PROD;
switch (ENV) {
  case PUBLISHING_ENVIRONMENT.DEV:
    new Vconsole();
    break;
  case PUBLISHING_ENVIRONMENT.TEST:
    new Vconsole();
    break;
  case PUBLISHING_ENVIRONMENT.PROD:
    break;
}

Vue.config.productionTip = false;
Vue.use(Vant);
Vue.use(VueBus);
Vue.prototype.$httpGet = get;
Vue.prototype.$httpPost = post;
Vue.prototype.$httpPostForm = postForm;
Vue.prototype.$httpPostFile = postFile;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
