<template>
  <div class="flex flex-col h-screen">
    <van-pull-refresh
      v-model="isRefreshing"
      @refresh="onRefresh"
      class="flex flex-1"
    >
      <!--      <van-list-->
      <!--        v-model="isLoading"-->
      <!--        :error.sync="error"-->
      <!--        error-text="请求失败，点击重新加载"-->
      <!--        @load="onLoad"-->
      <!--      >-->
      <van-swipe-cell v-for="item in list" :key="item.id" class="w-screen">
        <van-cell
          :title="item.name"
          @click="showEdit(item.id, item.name, item.twig)"
        />
        <template #right>
          <van-button
            square
            type="danger"
            text="删除"
            @click="delType(item.id)"
          />
        </template>
      </van-swipe-cell>
      <!--      </van-list>-->
    </van-pull-refresh>
    <van-button type="primary" @click="showAddDialog = true"
      >添加类型</van-button
    >
    <van-dialog
      v-model="showAddDialog"
      title="添加类型"
      show-cancel-button
      show-confirm-button
      @confirm="addNewType"
      @cancel="typeName = ''"
    >
      <van-field v-model="typeName" placeholder="请输入要添加的类型" />
      <van-field v-model="typeWeight" placeholder="请输入权重" type="number" />
    </van-dialog>
    <van-dialog
      v-model="showEditDialog"
      title="编辑类型"
      show-cancel-button
      show-confirm-button
      @confirm="editType"
      @cancel="editCancel"
    >
      <van-field v-model="typeName" placeholder="请输入修改后的类型" />
      <van-field v-model="typeWeight" placeholder="请输入权重" type="number" />
    </van-dialog>
  </div>
</template>

<script>
import {
  addProductType,
  delProductType,
  editProductType,
  getProductTypes,
  getUrl
} from "@/net/Api";
import { Toast } from "vant";

export default {
  name: "PageProductTypesMgr",
  data() {
    return {
      list: [],
      isLoading: false,
      isRefreshing: false,
      finished: false,
      showAddDialog: false,
      showEditDialog: false,
      typeName: "",
      typeWeight: "",
      curId: ""
    };
  },
  created() {
    this.initData();
    console.log(getProductTypes);
  },
  methods: {
    initData() {
      this.$httpGet(getUrl(getProductTypes)).then(resp => {
        this.list = resp.items;
      });
    },
    onRefresh() {
      this.isRefreshing = true;
      this.list = [];
      this.$httpGet(getUrl(getProductTypes))
        .then(resp => {
          this.list = resp.items;
        })
        .finally(() => {
          this.isRefreshing = false;
        });
    },
    addNewType() {
      if (this.typeName === "") {
        Toast("请输入类型");
        return;
      }
      this.$httpPost(getUrl(addProductType), {
        name: this.typeName
      }).then(() => {
        this.initData();
        this.typeName = "";
      });
    },
    delType(id) {
      this.$httpPost(getUrl(delProductType), {
        id: id
      }).then(() => {
        this.initData();
      });
    },
    showEdit(id, name, twig) {
      this.curId = id;
      this.typeName = name;
      this.showEditDialog = true;
      this.typeWeight = twig;
    },
    editType() {
      this.$httpPost(getUrl(editProductType), {
        id: this.curId,
        name: this.typeName,
        twig: this.typeWeight
      }).then(() => {
        this.initData();
        this.typeName = "";
        this.typeWeight = "";
      });
    },
    editCancel() {
      this.typeName = "";
      this.typeWeight = "";
    }
  }
};
</script>

<style scoped></style>
