<template>
  <div class="flex flex-col h-screen ">
    <div class="flex flex-1 overflow-scroll">
      <div class="overflow-scroll ">
        <van-sidebar v-model="activeTypeIndex" @change="onRefresh">
          <van-sidebar-item title="全部分类" key="-1" />
          <van-sidebar-item
            v-for="typeItem in typeList"
            :title="typeItem.name"
            :key="typeItem.id"
          />
        </van-sidebar>
      </div>
      <div
        class="flex flex-1 flex-col overflow-scroll"
        style="background: white"
      >
        <van-tabs v-model="activeStateIndex" @change="onRefresh" sticky>
          <van-tab title="全部状态"></van-tab>
          <van-tab title="已上架"></van-tab>
          <van-tab title="已下架"></van-tab>
        </van-tabs>
        <!--        <van-pull-refresh-->
        <!--          v-model="isRefreshing"-->
        <!--          @refresh="onRefresh"-->
        <!--        >-->
        <van-list
          v-model="isLoading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          class="overflow-scroll"
          v-show="this.goodsList.length !== 0"
        >
          <van-checkbox-group v-model="multiCheckResult" ref="checkboxGroup">
            <div
              v-for="goodItem in goodsList"
              :key="goodItem.id"
              @click="addProduct(goodItem.id)"
            >
              <van-swipe-cell>
                <template #left>
                  <van-button
                    square
                    type="primary"
                    text="上架"
                    class="h-full"
                    v-show="!goodItem.is_on_sale"
                    @click="doOnSale(goodItem)"
                  />
                  <van-button
                    square
                    type="danger"
                    text="下架"
                    class="h-full"
                    v-show="goodItem.is_on_sale"
                    @click="doOnSale(goodItem)"
                  />
                </template>
                <div class="p-2 flex flex-col " style="height: 100px">
                  <div class="flex flex-row items-center">
                    <img
                      :src="goodItem.pic_url"
                      style="width: 64px;height: 64px"
                    />
                    <div class="ml-3 flex-1 overflow-x-hidden">
                      <div class="overflow">{{ goodItem.name }}</div>
                      <div class="overflow">类别：{{ goodItem.tname }}</div>
                      <div class=" overflow">
                        ¥{{ goodItem.minprice }}~{{ goodItem.maxprice }}/{{
                          goodItem.unit
                        }}
                      </div>
                      <div>总库存：{{ goodItem.totalnumber }}</div>
                    </div>
                    <div
                      class="flex flex-col justify-start h-full items-center"
                    >
                      <van-tag type="success" v-show="goodItem.is_on_sale"
                        >已上架
                      </van-tag>
                      <van-tag type="danger" v-show="!goodItem.is_on_sale"
                        >已下架
                      </van-tag>
                      <van-checkbox
                        class="mt-3"
                        icon-size="24px"
                        :name="goodItem.id"
                        v-if="isMultiCheck"
                      ></van-checkbox>
                    </div>
                  </div>
                  <van-divider />
                </div>
                <template #right>
                  <van-button
                    square
                    type="danger"
                    text="删除"
                    class="h-full"
                    @click="delProduct(goodItem.id)"
                  />
                </template>
              </van-swipe-cell>
            </div>
          </van-checkbox-group>
        </van-list>

        <van-empty
          description="暂无数据"
          v-show="this.goodsList.length === 0"
        />
        <!--        </van-pull-refresh>-->
      </div>
    </div>
    <div v-if="isMultiCheck" class="p-1 flex">
      <van-button round type="primary" size="small" @click="onSale"
        >上架
      </van-button>
      <van-button round type="danger" size="small" class="ml-2" @click="offSale"
        >下架
      </van-button>
      <div class="flex-1"></div>
      <van-button round type="primary" size="small" @click="checkAll">
        全选
      </van-button>
      <van-button
        round
        type="primary"
        size="small"
        class="ml-2"
        @click="toggleAll"
        >反选
      </van-button>
    </div>
    <div class="flex">
      <van-button type="warning" @click="openSearchPanel">搜索 </van-button>
      <van-button type="danger" @click="isMultiCheck = !isMultiCheck"
        >{{ this.isMultiCheck ? "完成" : "编辑" }}
      </van-button>
      <van-button type="primary" class="flex-1" @click="addProduct()"
        >+ 新建商品
      </van-button>
    </div>
    <van-popup
      v-model="showSearchPanel"
      class="flex flex-col h-screen w-screen"
    >
      <form action="/">
        <van-search
          v-model="searchKey"
          show-action
          placeholder="请输入商品名"
          @search="onSearch"
          @cancel="showSearchPanel = false"
        />
      </form>
      <div class="flex-1">
        <van-list
          v-model="searchIsLoading"
          :finished="searchFinished"
          finished-text="没有更多了"
          @load="searchOnLoad"
          class="overflow-scroll"
          v-show="this.searchGoodsList.length !== 0"
        >
          <div
            v-for="goodItem in searchGoodsList"
            :key="goodItem.id"
            @click="addProduct(goodItem.id)"
          >
            <van-swipe-cell>
              <template #left>
                <van-button
                  square
                  type="primary"
                  text="上架"
                  class="h-full"
                  v-show="!goodItem.is_on_sale"
                  @click="doOnSale(goodItem)"
                />
                <van-button
                  square
                  type="danger"
                  text="下架"
                  class="h-full"
                  v-show="goodItem.is_on_sale"
                  @click="doOnSale(goodItem)"
                />
              </template>
              <div class="p-2 flex flex-col " style="height: 100px">
                <div class="flex flex-row items-center">
                  <img
                    :src="goodItem.pic_url"
                    style="width: 64px;height: 64px"
                  />
                  <div class="ml-3 flex-1 overflow-x-hidden">
                    <div class="overflow">{{ goodItem.name }}</div>
                    <div class="overflow">类别：{{ goodItem.tname }}</div>
                    <div class=" overflow">
                      ¥{{ goodItem.minprice }}~{{ goodItem.maxprice }}/{{
                        goodItem.unit
                      }}
                    </div>
                    <div>总库存：{{ goodItem.totalnumber }}</div>
                  </div>
                  <div class="flex flex-col justify-start h-full items-center">
                    <van-tag type="success" v-show="goodItem.is_on_sale"
                      >已上架
                    </van-tag>
                    <van-tag type="danger" v-show="!goodItem.is_on_sale"
                      >已下架
                    </van-tag>
                  </div>
                </div>
                <van-divider />
              </div>
              <template #right>
                <van-button
                  square
                  type="danger"
                  text="删除"
                  class="h-full"
                  @click="delProduct(goodItem.id)"
                />
              </template>
            </van-swipe-cell>
          </div>
        </van-list>
        <van-empty
          description="暂无数据"
          v-show="this.searchGoodsList.length === 0"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  delGood,
  getGoodsList,
  getGoodsTypes,
  getUrl,
  setBatchGoodsState,
  setGoodState
} from "@/net/Api";
import { Dialog, Toast } from "vant";

export default {
  name: "PageProductMgr",
  data() {
    return {
      page: 1,
      goodsList: [],
      typeList: [],
      isLoading: false,
      isRefreshing: false,
      finished: false,
      activeTypeIndex: 0,
      activeStateIndex: 0,
      isMultiCheck: false,
      multiCheckResult: [],
      showSearchPanel: false,
      searchKey: "",
      searchPage: 1,
      searchGoodsList: [],
      searchIsLoading: false,
      searchFinished: false
    };
  },
  created() {
    this.getTypeList();
    this.onRefresh();
  },
  methods: {
    getTypeList() {
      this.$httpPost(getUrl(getGoodsTypes)).then(resp => {
        this.typeList = resp.items;
      });
    },
    onRefresh() {
      console.log("act：" + this.activeTypeIndex);
      this.page = 1;
      this.isRefreshing = true;
      this.finished = false;
      this.$httpPost(getUrl(getGoodsList), {
        page: this.page,
        limit: 20,
        tid: this.getTypeId(this.activeTypeIndex),
        isonsale: this.getState(this.activeStateIndex)
      })
        .then(resp => {
          this.goodsList = [];
          this.goodsList = resp.items;
        })
        .finally(() => {
          this.isRefreshing = false;
        });
    },
    onLoad() {
      this.page++;
      this.isLoading = true;
      this.$httpPost(getUrl(getGoodsList), {
        page: this.page,
        limit: 20,
        tid: this.getTypeId(this.activeTypeIndex),
        isonsale: this.getState(this.activeStateIndex)
      })
        .then(resp => {
          if (resp.items.length === 0) {
            this.finished = true;
            Toast("暂无更多数据");
          }
          this.goodsList.push(...resp.items);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getState(index) {
      let state = "";
      switch (index) {
        case 0:
          break;
        case 1:
          state += "1";
          break;
        case 2:
          state += "0";
          break;
      }
      return state;
    },
    getTypeId(index) {
      if (this.typeList.length === 0) {
        return "";
      }
      if (index === 0) {
        return "";
      }
      return this.typeList[index - 1].id;
    },
    delProduct(id) {
      Dialog.confirm({
        message: "确认删除此产品"
      })
        .then(() => {
          this.$httpPost(getUrl(delGood), { id: id })
            .then(() => {
              this.onRefresh();
              Toast("删除成功");
            })
            .catch(() => {
              Toast("删除失败");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    checkAll() {
      this.$refs.checkboxGroup.toggleAll(true);
    },
    toggleAll() {
      this.$refs.checkboxGroup.toggleAll();
    },
    onSale() {
      Dialog.confirm({
        message: "确定批量上架？"
      })
        .then(() => {
          this.$httpPost(getUrl(setBatchGoodsState), {
            ids: this.multiCheckResult,
            status: 1
          })
            .then(() => {
              this.onRefresh();
              Toast("批量上架成功");
            })
            .catch(() => {
              Toast("批量上架失败");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    offSale() {
      Dialog.confirm({
        message: "确定批量下架？"
      })
        .then(() => {
          this.$httpPost(getUrl(setBatchGoodsState), {
            ids: this.multiCheckResult,
            status: 0
          })
            .then(() => {
              this.onRefresh();
              Toast("批量下架成功");
            })
            .catch(() => {
              Toast("批量下架失败");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    addProduct(id = "") {
      this.$router.push({ name: "PageProductEdit", params: { id: id } });
    },
    doOnSale(prod) {
      this.$httpPost(getUrl(setGoodState), {
        id: prod.id,
        status: prod.is_on_sale ? 0 : 1
      })
        .then(() => {
          this.onRefresh();
          Toast("操作成功");
        })
        .catch(() => {
          Toast("操作失败");
        });
    },
    onSearch() {
      if (this.searchKey === "" || this.searchKey === null) {
        Toast("请输入商品名称");
      }
      this.searchGoodsList = [];
      this.searchPage = 1;
      this.searchFinished = false;
      this.$httpPost(getUrl(getGoodsList), {
        page: this.page,
        limit: 20,
        tid: "",
        isonsale: "",
        name: this.searchKey
      }).then(resp => {
        // this.searchGoodsList = [];
        this.searchGoodsList = resp.items;
      });
    },
    searchOnLoad() {
      this.searchPage++;
      this.searchIsLoading = true;
      this.$httpPost(getUrl(getGoodsList), {
        page: this.page,
        limit: 20,
        tid: "",
        isonsale: "",
        name: this.searchKey
      })
        .then(resp => {
          if (resp.items.length === 0) {
            this.searchFinished = true;
            Toast("暂无更多数据");
          }
          this.searchGoodsList.push(...resp.items);
        })
        .finally(() => {
          this.searchIsLoading = false;
        });
    },
    openSearchPanel() {
      this.searchKey = "";
      this.searchPage = 1;
      this.searchGoodsList = [];
      this.searchIsLoading = false;
      this.searchFinished = false;
      this.showSearchPanel = true;
    }
  }
};
</script>

<style scoped>
.overflow {
  width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
</style>
