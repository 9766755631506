<template>
  <div class="flex h-screen flex-col w-screen">
    <div class="flex-1 overflow-x-auto">
      <van-cell-group>
        <van-field
          v-model="product.goods.name"
          label="商品名称"
          placeholder="请输入商品名称"
          type="textarea"
        />
        <van-field
          is-link
          readonly
          label="展示分类"
          placeholder="请选择并添加所属分类"
          @click="showTypePicker = true"
        />
        <div>
          <van-tag
            closeable
            size="large"
            type="primary"
            @close="delTag(index)"
            class="ml-4 mt-2"
            :key="item.tid"
            v-for="(item, index) in this.product.goodsMaps"
            >{{ item.name }}({{ item.twig }})
          </van-tag>
        </div>
        <van-cell center title="开启自提">
          <template #right-icon>
            <van-switch
              v-model="product.goods.takein"
              size="24"
              :disabled="permissionList.indexOf('2') < 0"
            />
          </template>
        </van-cell>

        <van-field
          is-link
          readonly
          v-if="product.goods.takein"
          label="商品参数"
          placeholder="点击查看自提参数"
          @click="showSelfPickDialog = true"
        />

        <van-cell center title="开启外卖">
          <template #right-icon>
            <van-switch
              v-model="product.goods.takeout"
              size="24"
              :disabled="permissionList.indexOf('1') < 0"
            />
          </template>
        </van-cell>
        <van-cell center title="开启邮寄">
          <template #right-icon>
            <van-switch
              v-model="product.goods.takepost"
              size="24"
              :disabled="permissionList.indexOf('3') < 0"
            />
          </template>
        </van-cell>
        <van-cell center title="开启特价">
          <template #right-icon>
            <van-switch v-model="product.goods.isNew" size="24" />
          </template>
        </van-cell>
        <van-cell center title="设为非券品">
          <template #right-icon>
            <van-switch v-model="product.goods.isHot" size="24" />
          </template>
        </van-cell>
        <div v-if="product.goods.takein && product.goods.isHot">
          <quill-editor :init="editorInit" v-model="product.goods.detail" />
        </div>
        <van-cell center title="开售状态">
          <template #right-icon>
            <van-switch
              v-model="product.goods.isOnSale"
              size="24"
              active-value="1"
              inactive-value="0"
            />
          </template>
        </van-cell>
        <van-field
          v-model="product.goods.stock"
          label="库存"
          type="number"
          placeholder="请输入库存"
        />
        <van-field
          v-model="product.goods.counterPrice"
          label="专柜价格"
          type="number"
          placeholder="请输入专柜价格"
        />
        <van-field
          v-model="product.goods.retailPrice"
          label="当前价格"
          type="number"
          placeholder="请输入当前价格"
        />
        <van-field
          v-model="product.goods.packing"
          label="包装费"
          type="number"
          placeholder="请输入包装费"
        />

        <van-cell center title="商品图片">
          <template #right-icon>
            <van-uploader
              multiple
              :max-count="1"
              :after-read="pickProductPic"
              @delete="product.goods.picUrl = ''"
              v-model="productPicList"
            />
          </template>
        </van-cell>
        <van-cell center title="宣传画廊"></van-cell>
        <div class="pl-4">
          <van-uploader
            v-model="galleryList"
            multiple
            max-count="5"
            :after-read="pickGalleryPic"
          />
        </div>
        <van-field
          v-model="product.goods.unit"
          label="商品单位"
          placeholder="请输入商品单位"
        />
        <van-field
          is-link
          readonly
          label="关键字"
          placeholder="请选择并添加关键字"
          @click="showKeyWordDialog = true"
        />
        <div>
          <van-tag
            closeable
            size="large"
            type="primary"
            @close="delKeyWord(index)"
            class="ml-4 mt-2"
            :key="item.tid"
            v-for="(item, index) in this.keyWordList"
            >{{ item }}
          </van-tag>
        </div>
        <van-field
          v-model="cateText"
          is-link
          readonly
          label="所属分类"
          placeholder="请选择所属分类"
          @click="showCatePicker = true"
        />
        <van-field
          v-model="product.goods.brief"
          label="商品简介"
          placeholder="请输入商品简介"
          type="textarea"
        />
      </van-cell-group>
      <div class="h-4 "></div>
      <van-cell
        center
        title="核销策略"
        style="padding-top: 0;padding-bottom: 0;padding-right: 0"
      >
        <template #right-icon>
          <van-dropdown-menu class="w-1/2">
            <van-dropdown-item
              v-model="product.info.timeStrategy"
              :options="option1"
            />
          </van-dropdown-menu>
        </template>
      </van-cell>
      <div v-if="product.info.timeStrategy === 1">
        <van-cell
          center
          title="起止时间"
          style="padding-top: 0;padding-bottom: 0;padding-right: 0"
        >
          <template #right-icon>
            <van-button
              @click="showStartTimePicker = true"
              style="font-size: 12px"
              class="flex-1"
              >{{
                product.info.startTime === null || product.info.startTime === ""
                  ? "开始时间"
                  : product.info.startTime
              }}
            </van-button>
            <van-button
              @click="showEndTimePicker = true"
              style="font-size: 12px"
              class="flex-1"
              >{{
                product.info.endTime === null || product.info.endTime === ""
                  ? "结束时间"
                  : product.info.endTime
              }}
            </van-button>
          </template>
        </van-cell>
      </div>
      <div v-if="product.info.timeStrategy === 4">
        <van-field
          v-model="product.info.period"
          label="核销天数"
          placeholder="请输入几日后核销"
          type="number"
        />
      </div>
      <div class="h-4 "></div>
      <!--      <van-field-->
      <!--        is-link-->
      <!--        readonly-->
      <!--        label="规格"-->
      <!--        placeholder="点击添加规格和商品"-->
      <!--        @click="doShowSpecDialog(null)"-->
      <!--      />-->

      <!--      <van-field-->
      <!--        is-link-->
      <!--        readonly-->
      <!--        label="规格"-->
      <!--        placeholder="点击查看规格"-->
      <!--        @click="showSpecEditDialog = true"-->
      <!--      />-->
      <!--      <van-field-->
      <!--        is-link-->
      <!--        readonly-->
      <!--        label="sku"-->
      <!--        placeholder="点击查看sku"-->
      <!--        @click="showSkuDialog = true"-->
      <!--      />-->
      <van-popup v-model="showCatePicker" round position="bottom">
        <van-cascader
          v-model="product.goods.categoryId"
          title="请选择所属分类"
          :options="cateOptions"
          :field-names="cateFieldNames"
          @close="showCatePicker = false"
          @finish="onCatePickFinish"
        />
      </van-popup>
      <van-popup v-model="showTypePicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="typeList"
          value-key="name"
          @cancel="showTypePicker = false"
          @confirm="onTypePicked"
        />
      </van-popup>
      <van-popup v-model="showStartTimePicker" round position="bottom">
        <van-datetime-picker
          type="datetime"
          title="选择开始时间"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onStartTimeConfirm"
          @cancel="showStartTimePicker = false"
        />
      </van-popup>
      <van-popup v-model="showEndTimePicker" round position="bottom">
        <van-datetime-picker
          type="datetime"
          title="选择结束时间"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onEndTimeConfirm"
          @cancel="showEndTimePicker = false"
        />
      </van-popup>
      <van-popup v-model="showSelfPickDialog" round position="bottom">
        <div class="h-80 flex flex-col mt-4">
          <div class="flex-1">
            <div v-for="(item, index) in product.attributes" :key="item">
              <van-swipe-cell>
                <van-cell :title="item.attribute" :value="item.value" />
                <template #right>
                  <van-button
                    square
                    type="danger"
                    text="删除"
                    class="h-full"
                    @click="product.attributes.splice(index, 1)"
                  />
                </template>
              </van-swipe-cell>
            </div>
          </div>
          <van-button type="primary" @click="showSelfPickAddParamDialog = true">
            添加参数
          </van-button>
        </div>
      </van-popup>
      <van-popup v-model="showSpecEditDialog" position="bottom">
        <div class="h-80 flex flex-col">
          <div class="flex flex-1 flex-row overflow-scroll">
            <div class="overflow-scroll">
              <van-sidebar v-model="activeSpec">
                <van-sidebar-item
                  v-for="(item, index) in formattedSpecList"
                  :title="item.name"
                  :key="index"
                  @click="onSideItemClick(index)"
                />
              </van-sidebar>
            </div>
            <div class="flex flex-1 flex-col overflow-scroll">
              <div class="flex-1 overflow-scroll">
                <van-swipe-cell
                  v-for="(specVal, index) in activeSpecValues"
                  :key="specVal"
                >
                  <van-cell :value="specVal" />
                  <template #right>
                    <van-button
                      square
                      text="删除"
                      type="danger"
                      class="delete-button"
                      @click="delSpecVal(index)"
                    />
                  </template>
                </van-swipe-cell>
              </div>
              <van-button
                type="primary"
                class="m-1"
                plain
                @click="showSpecValueAddDialog = true"
              >
                添加规格值
              </van-button>
            </div>
          </div>
          <van-button type="primary" @click="showSpecAddDialog = true">
            添加规格
          </van-button>
        </div>
      </van-popup>
      <van-popup v-model="showSkuDialog" position="bottom">
        <div class="flex flex-col h-screen">
          <div class="flex flex-1 flex-row overflow-scroll">
            <div
              v-for="(prod, index) in product.products"
              :key="prod"
              @click="doShowSpecDialog(prod)"
            >
              <van-swipe-cell>
                <div class="p-2 flex flex-col ">
                  <div class="flex flex-row items-center w-screen">
                    <img :src="prod.url" style="width: 64px;height: 64px" />
                    <div class="ml-3 flex-1 overflow-y-hidden">
                      <div class="overflow">
                        规格：
                        <van-tag
                          type="success"
                          v-for="tag in prod.specifications"
                          class="ml-1"
                          :key="tag"
                          >{{ tag }}
                        </van-tag>
                      </div>
                      <div class="flex flex-row  mt-2 flex-1">
                        <div class="flex-1">
                          <div class="overflow">数量：{{ prod.number }}</div>
                          <div class=" overflow">价格：¥{{ prod.price }}</div>
                        </div>
                        <div class="flex-1">
                          <div class="overflow">
                            一级返利：{{ prod.rebate1 }}
                          </div>
                          <div class="overflow">
                            二级返利：{{ prod.rebate2 }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <template #right>
                  <van-button
                    square
                    type="danger"
                    text="删除"
                    class="h-full"
                    @click="delProdSpec(index)"
                  />
                </template>
              </van-swipe-cell>
            </div>
          </div>
          <div class="flex flex-row w-screen">
            <van-button
              type="danger"
              class="flex-1"
              @click="showSkuDialog = false"
            >
              取消
            </van-button>
            <van-button
              type="primary"
              class="flex-1"
              @click="doShowSpecDialog(null)"
            >
              添加sku
            </van-button>
          </div>
        </div>
      </van-popup>

      <van-dialog
        v-model="showTypeWeightDialog"
        title="编辑权重"
        show-cancel-button
        show-confirm-button
        @confirm="editTypeWeight"
      >
        <van-field
          v-model="typeWeight"
          placeholder="请输入权重"
          type="number"
        />
      </van-dialog>
      <van-dialog
        v-model="showKeyWordDialog"
        title="编辑关键词"
        show-cancel-button
        show-confirm-button
        @confirm="addKeyWord"
      >
        <van-field v-model="keyWord" placeholder="请输入关键词" />
      </van-dialog>
      <van-dialog
        v-model="showSelfPickAddParamDialog"
        title="添加自提参数"
        show-cancel-button
        show-confirm-button
        @confirm="AddSelfPickParam"
      >
        <van-field v-model="attrName" placeholder="请输参数名" />
        <van-field v-model="attrValue" placeholder="请输入参数值" />
      </van-dialog>
      <van-dialog
        v-model="showSpecAddDialog"
        title="添加规格"
        show-cancel-button
        show-confirm-button
        @confirm="addSpec"
      >
        <van-field v-model="specName" placeholder="请输规格" />
        <van-field v-model="specValue" placeholder="请输入规格值" />
      </van-dialog>
      <van-dialog
        v-model="showSpecValueAddDialog"
        title="添加规格值"
        show-cancel-button
        show-confirm-button
        @confirm="addSpecVal"
      >
        <van-field v-model="specValue" placeholder="请输入规格值" />
      </van-dialog>
      <van-dialog
        v-model="showSpecDialog"
        title="编辑规格和商品"
        show-cancel-button
        show-confirm-button
        class="overflow-x-scroll"
        @confirm="editProdWithSpec"
      >
        <van-cell
          v-for="(spec, index) in formattedSpecList"
          center
          :title="spec.name"
          :key="index"
          @click="doShowSpecValDialog(index)"
        >
          <template #right-icon>
            <div>
              {{
                cacheSkuResList[index] === undefined
                  ? ""
                  : cacheSkuResList[index].cSpec
              }}
            </div>
          </template>
        </van-cell>
        <van-cell center title="规格图片">
          <template #right-icon>
            <van-uploader
              multiple
              :max-count="1"
              :after-read="pickSpecPic"
              @delete="cacheProdWithSpec.picUrl = ''"
              v-model="cacheSpecPic"
            />
          </template>
        </van-cell>
        <van-field
          v-model="cacheProdWithSpec.price"
          placeholder="请输入商品售价"
          type="number"
        />
        <van-field
          v-model="cacheProdWithSpec.number"
          placeholder="请输入商品数量"
          type="number"
        />
        <van-field
          v-model="cacheProdWithSpec.code"
          placeholder="请输入货品编码"
        />
        <van-field
          v-model="cacheProdWithSpec.rebate1"
          placeholder="请输入一级返利"
          type="number"
        />
        <van-field
          v-model="cacheProdWithSpec.rebate2"
          placeholder="请输入二级返利"
          type="number"
        />
        <van-cell center title="货品图片">
          <template #right-icon>
            <van-uploader
              multiple
              :max-count="1"
              :after-read="pickSpecProdPic"
              @delete="cacheProdWithSpec.url = ''"
              v-model="cacheSpecProdPic"
            />
          </template>
        </van-cell>
      </van-dialog>
      <van-popup v-model="showSpecValPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="pickerSpecValList"
          @cancel="showSpecValPicker = false"
          @confirm="onSpecValPickerConfirm"
        />
      </van-popup>
    </div>
    <van-button @click="submit" type="primary">保存</van-button>
  </div>
</template>

<script>
import {
  addProduct,
  editProduct,
  getCateAndBrand,
  getGoodDetail,
  getGoodsTypes,
  getUrl,
  uploadPic
} from "@/net/Api";
import { Toast } from "vant";
import dayjs from "dayjs";

export default {
  name: "PageProductEdit",
  data() {
    return {
      product: {
        goodsMaps: [
          // {
          //   twig: 4,
          //   name: "日常用品",
          //   tid: 6272
          // }
        ],
        categoryIds: [],
        info: {
          timeStrategy: 4, //核销策略 0默认，1时间段（startTime，endTime），4天（period）
          startTime: null, //2020-03-01 00:00:00
          endTime: null,
          period: 365
        },
        goods: {
          stock: 999,
          goodsSn: new Date().getTime(),
          name: "",
          categoryId: null,
          gallery: [],
          keywords: "",
          brief: "",
          isOnSale: "0",
          picUrl: "",
          takeout: false,
          takein: false,
          takepost: false,
          packing: null,
          isNew: false,
          isHot: false,
          unit: "",
          counterPrice: null,
          retailPrice: null
          // detail: "<p>可免费使用包间食堂食外带均可，可免费打包</p>"
        },
        specifications: [
          {
            specification: "规格",
            value: "标准",
            picUrl: ""
          }
        ],
        products: [
          {
            specifications: ["标准"],
            price: 0,
            rebate1: 0,
            rebate2: 0,
            number: 0,
            url: "",
            code: ""
          }
        ],
        attributes: [
          //商品參數
          // {
          //   id: 619512,
          //   goodsId: 221633,
          //   attribute: "大小", //商品参数名称
          //   value: "标件", //商品参数值
          //   addTime: "2021-03-18 09:48:22",
          //   updateTime: "2021-03-18 09:48:22",
          //   deleted: false
          // }
        ]
      },
      editorInit: {
        language: "zh_CN",
        convert_urls: false,
        plugins: [
          "advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount"
        ],
        toolbar: [
          "searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample",
          "hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen"
        ],
        images_upload_handler: function(blobInfo, success, failure) {
          this.$httpPostFile(getUrl(uploadPic), blobInfo.blob())
            .then(response => {
              success(response.url);
            })
            .catch(() => {
              failure("上传失败，请重新上传");
            });
        }
      },
      typeList: [],
      showTypePicker: false,
      showKeyWordDialog: false,
      keyWord: "",
      keyWordList: [],
      showCatePicker: false,
      typeFieldNames: {
        text: "name",
        value: "id"
      },
      cacheTypeObj: {
        name: "",
        tid: null,
        twig: null
      },
      showTypeWeightDialog: false,
      typeWeight: null,
      cateText: "",
      cateOptions: [],
      cateFieldNames: {
        text: "label",
        value: "value",
        children: "children"
      },
      productPicList: [],
      galleryList: [],
      permissionList: [],
      prodWithSpecList: [
        // {
        //   specification: "规格",
        //   value: "标准",
        //   picUrl: "",
        //   specifications: ["标准"],
        //   price: 0,
        //   rebate1: 0,
        //   rebate2: 0,
        //   number: 0,
        //   url: "",
        //   code: ""
        // }
      ],
      showSpecDialog: false,
      cacheProdWithSpec: {
        // specification: null,
        // value: null,
        picUrl: "",
        specifications: [],
        price: null,
        rebate1: null,
        rebate2: null,
        number: null,
        url: "",
        code: null
      },
      cacheSpecPic: [],
      cacheSpecProdPic: [],
      value1: 0,
      option1: [
        { text: "不限时", value: 0 },
        { text: "区间核销", value: 1 },
        { text: "买后计时", value: 4 }
      ],
      minDate: new Date(),
      maxDate: new Date(2025, 10, 1),

      showStartTimePicker: false,
      showEndTimePicker: false,
      showSelfPickDialog: false,
      showSelfPickAddParamDialog: false,
      attrName: null,
      attrValue: null,
      showSpecEditDialog: false,
      formattedSpecList: [
        // {
        //   name: "",
        //   values: []
        // }
      ],
      activeSpec: null,
      activeSpecValues: [],
      showSpecAddDialog: false,
      showSpecValueAddDialog: false,
      specName: null,
      specValue: null,
      showSkuDialog: false,
      showSpecValPicker: false,
      pickerSpecValIndex: -1,
      pickerSpecValList: [],
      cacheSkuResList: [
        // {pSpec: null,
        //   cSpec: null}
      ]
    };
  },
  async created() {
    await this.$httpPost(getUrl(getGoodsTypes)).then(resp => {
      this.typeList = resp.items;
    });

    await this.$httpGet(getUrl(getCateAndBrand)).then(resp => {
      this.cateOptions = resp.categoryList;
      console.log(resp.Delivery.supportType.split(","));
      this.permissionList = resp.Delivery.supportType.split(",");
    });

    let id = this.$route.params.id;
    if (id != null && id !== "") {
      await this.$httpGet(getUrl(getGoodDetail), {
        id: id
      }).then(resp => {
        this.product = resp;
        this.product.goods.isOnSale = this.product.goods.isOnSale + "";
        this.keyWordList = this.product.goods.keywords.split(",");
        this.cateText = "";
        let pItem = null;
        let pId = this.product.categoryIds[0];
        let cId = this.product.categoryIds[1];
        if (pId !== null) {
          this.cateOptions.forEach(item => {
            if (item.value === pId) {
              this.cateText += "/";
              this.cateText += item.label;
              pItem = item;
            }
          });
        }
        if (cId !== null && pItem !== null) {
          pItem.children.forEach(item => {
            if (item.value === cId) {
              this.cateText += "/";
              this.cateText += item.label;
            }
          });
        }
        if (
          this.product.goods.picUrl !== null ||
          this.product.goods.picUrl !== ""
        ) {
          this.productPicList.push({
            url: this.product.goods.picUrl,
            isImage: true
          });
        }
        if (this.product.goods.gallery.length > 0) {
          this.product.goods.gallery.forEach(item => {
            this.galleryList.push({ url: item, isImage: true });
          });
        }
        // if (!this.product.products.length > 0) {
        //   this.prodWithSpecList.push({
        //     specification: "规格",
        //     value: "标准",
        //     picUrl: "",
        //     specifications: ["标准"],
        //     price: 0,
        //     rebate1: 0,
        //     rebate2: 0,
        //     number: 0,
        //     url: "",
        //     code: ""
        //   });
        // }
      });
    } else {
      // this.prodWithSpecList.push({
      //   specification: "规格",
      //   value: "标准",
      //   picUrl: "",
      //   specifications: ["标准"],
      //   price: 0,
      //   rebate1: 0,
      //   rebate2: 0,
      //   number: 0,
      //   url: "",
      //   code: ""
      // });
    }
    this.formatSpecList();
  },
  methods: {
    submit() {
      this.product.goods.keywords = this.keyWordList.toString();

      this.product.goods.gallery = [];
      this.galleryList.forEach(item => {
        this.product.goods.gallery.push(item.url);
      });

      // this.product.specifications = [];
      // this.product.products = [];
      // this.prodWithSpecList.forEach(prod => {
      //   this.product.specifications.push({
      //     specification: prod.specification,
      //     value: prod.value,
      //     picUrl: prod.picUrl
      //   });
      //   this.product.products.push({
      //     specifications: prod.specifications,
      //     price: prod.price, //标准规格商品的价格
      //     rebate1: prod.rebate1, //标准规格商品的一级返利
      //     rebate2: prod.rebate2, //标准规格商品的二级返利
      //     number: prod.number, //标准规格商品的数量
      //     prod: prod.prod,
      //     code: prod.code
      //   });
      // });

      if (
        this.product.products.length === 1 &&
        this.product.products[0].price === 0
      ) {
        this.product.products[0].price = this.product.goods.retailPrice;
        this.product.products[0].number = 1;
      }

      if (
        this.product.goods.id === null ||
        this.product.goods.id === undefined
      ) {
        this.$httpPost(getUrl(addProduct), this.product)
          .then(() => {
            Toast("添加成功");
            this.$router.back();
          })
          .catch(() => {
            // Toast("添加失败");
          });
      } else {
        this.$httpPost(getUrl(editProduct), this.product)
          .then(() => {
            Toast("修改成功");
            this.$router.back();
          })
          .catch(() => {
            // Toast("修改失败");
          });
      }
    },
    onCatePickFinish({ selectedOptions }) {
      this.showCatePicker = false;
      this.cateText = selectedOptions.map(option => option.label).join("/");
    },
    onTypePicked(value) {
      this.showTypePicker = false;
      console.log(value);
      let isInArr = false;
      this.product.goodsMaps.forEach(item => {
        if (item.tid === value.id) {
          isInArr = true;
        }
      });
      if (!isInArr) {
        this.cacheTypeObj.name = value.name;
        this.cacheTypeObj.tid = value.id;
        this.showTypeWeightDialog = true;
      }
    },
    editTypeWeight() {
      this.cacheTypeObj.twig = this.typeWeight;
      this.product.goodsMaps.push({
        name: this.cacheTypeObj.name,
        tid: this.cacheTypeObj.tid,
        twig: this.cacheTypeObj.twig
      });
      this.typeWeight = 0;
      this.cacheTypeObj.tid = null;
      this.cacheTypeObj.name = "";
      this.cacheTypeObj.twig = null;
    },
    delTag(index) {
      console.log(index);
      this.product.goodsMaps.splice(index, 1);
    },
    addKeyWord() {
      this.keyWordList.push(this.keyWord);
      this.keyWord = "";
    },
    delKeyWord(index) {
      console.log(index);
      this.keyWordList.splice(index, 1);
    },
    pickProductPic(file) {
      console.log(file);
      this.$httpPostFile(getUrl(uploadPic), file.file).then(response => {
        this.product.goods.picUrl = response.url;
      });
    },
    pickGalleryPic(RES, msg) {
      console.log(msg.index);
      this.$httpPostFile(getUrl(uploadPic), RES.file).then(response => {
        this.galleryList[msg.index].url = response.url;
      });
    },
    doShowSpecDialog(productWithSpec = null) {
      this.cacheSpecPic = [];
      this.cacheSpecProdPic = [];
      this.cacheProdWithSpec = {
        picUrl: "",
        specifications: [],
        price: null,
        rebate1: null,
        rebate2: null,
        number: null,
        url: "",
        code: null
      };
      if (productWithSpec !== null) {
        this.cacheProdWithSpec = productWithSpec;
        this.cacheSpecPic.push({ url: productWithSpec.picUrl, isImage: true });
        this.cacheSpecProdPic.push({ url: productWithSpec.url, isImage: true });
      }

      // cacheSkuResList
      for (let i = 0; i < this.formattedSpecList.length; i++) {
        this.cacheSkuResList.push({
          pSpec: null,
          cSpec: ""
        });
      }
      console.log(JSON.stringify(this.cacheSkuResList[0]) + "111111");
      this.showSpecDialog = true;
    },
    pickSpecPic(RES) {
      this.$httpPostFile(getUrl(uploadPic), RES.file).then(response => {
        this.cacheProdWithSpec.picUrl = response.url;
      });
    },
    pickSpecProdPic(RES) {
      this.$httpPostFile(getUrl(uploadPic), RES.file).then(response => {
        this.cacheProdWithSpec.url = response.url;
      });
    },
    editProdWithSpec() {
      this.prodWithSpecList.push({
        specification: this.cacheProdWithSpec.specification,
        value: this.cacheProdWithSpec.value,
        picUrl: this.cacheProdWithSpec.picUrl,
        specifications: [this.cacheProdWithSpec.specification],
        price: this.cacheProdWithSpec.price,
        rebate1: this.cacheProdWithSpec.rebate1,
        rebate2: this.cacheProdWithSpec.rebate2,
        number: this.cacheProdWithSpec.number,
        url: this.cacheProdWithSpec.url,
        code: this.cacheProdWithSpec.code
      });
    },
    delProdSpec(index) {
      this.product.products.splice(index, 1);
    },
    onStartTimeConfirm(val) {
      console.log(dayjs(val).format("YYYY-MM-DD HH:mm:ss"));
      this.product.info.startTime = dayjs(val).format("YYYY-MM-DD HH:mm:ss");
      this.showStartTimePicker = false;
    },
    onEndTimeConfirm(val) {
      console.log(dayjs(val).format("YYYY-MM-DD HH:mm:ss"));
      this.product.info.endTime = dayjs(val).format("YYYY-MM-DD HH:mm:ss");
      this.showEndTimePicker = false;
    },
    AddSelfPickParam() {
      this.product.attributes.push({
        attribute: this.attrName, //商品参数名称
        value: this.attrValue
      });
      this.attrName = null;
      this.attrValue = null;
    },
    formatSpecList() {
      this.product.specifications.forEach(item => {
        let hasSpec = false;
        // 二级不验证 宽进严出
        this.formattedSpecList.forEach(spec => {
          if (spec.name === item.specification) {
            hasSpec = true;
            spec.values.push(item.value);
          }
        });

        if (!hasSpec) {
          this.formattedSpecList.push({
            name: item.specification,
            values: [item.value]
          });
        }
      });
      this.activeSpec = 0;
      this.activeSpecValues = this.formattedSpecList[this.activeSpec].values;
    },
    onSideItemClick(index) {
      console.log(index);
      this.activeSpecValues = this.formattedSpecList[index].values;
    },
    addSpec() {
      let hasSpec = false;
      this.formattedSpecList.forEach(item => {
        if (this.specName === item.specName) {
          hasSpec = true;
        }
      });
      if (hasSpec) {
        Toast("请勿添加重复规格");
      } else {
        if (
          this.specName === null ||
          this.specName === "" ||
          this.specValue === null ||
          this.specValue === ""
        ) {
          Toast("请勿添加空数据");
        }
        this.formattedSpecList.push({
          name: this.specName,
          values: [this.specValue]
        });
        this.specName = null;
        this.specValue = null;
      }
    },
    addSpecVal() {
      let hasSpecVal = false;
      this.formattedSpecList[this.activeSpec].values.forEach(item => {
        if (this.specValue === item) {
          hasSpecVal = true;
        }
      });
      if (hasSpecVal) {
        Toast("请勿添加重复规格值");
      } else {
        if (this.specValue === null || this.specValue === "") {
          Toast("请勿添加空数据");
        }
        this.formattedSpecList[this.activeSpec].values.push(this.specValue);
        this.specName = null;
        this.specValue = null;
      }
    },
    delSpecVal(index) {
      // todo 商品删除含有此规格值的项
      this.formattedSpecList[this.activeSpec].values.splice(index, 1);
      if (this.formattedSpecList[this.activeSpec].values.length === 0) {
        this.formattedSpecList.splice(this.activeSpec, 1);
      }
    },
    doShowSpecValDialog(index) {
      this.pickerSpecValList = this.formattedSpecList[index].values;
      this.pickerSpecValIndex = index;
      this.showSpecValPicker = true;
    },
    onSpecValPickerConfirm(value) {
      console.log(
        value +
          this.pickerSpecValIndex +
          this.formattedSpecList[this.pickerSpecValIndex].name
      );
      this.cacheSkuResList[
        this.pickerSpecValIndex
      ].pSpec = this.formattedSpecList[this.pickerSpecValIndex].name;
      this.cacheSkuResList[this.pickerSpecValIndex].cSpec = value;
      this.showSpecValPicker = false;
    }
  }
};
</script>

<style scoped></style>
