<template>
  <div class="w-screen h-screen">
    <van-overlay
      :show="loading"
      class="flex flex-col justify-center items-center"
      class-name="bg-transparent"
    >
      <div class="bg-white flex-wrap p-6 rounded bg-transparent">
        <van-loading size="24px" type="spinner">加载中...</van-loading>
      </div>
    </van-overlay>
    <!--    <van-popup :show="loading">-->
    <!--      <van-loading size="24px">加载中...</van-loading>-->
    <!--    </van-popup>-->

    <!--    <keep-alive>-->
    <!--      <router-view v-if="$route.meta.keepAlive" />-->
    <!--    </keep-alive>-->
    <router-view />
  </div>
</template>
<script>
export default {
  name: "app",
  created() {
    this.$bus.on("show-loading", showLoading => {
      this.loading = showLoading;
    });
  },
  beforeDestroy() {
    this.$bus.off("show-loading", () => {
      this.loading = false;
    });
  },
  data() {
    return {
      loading: false
    };
  },
  mounted() {},
  methods: {}
};
</script>
<style></style>
