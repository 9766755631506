<template>
  <div class="flex justify-center items-center h-screen">no such route...</div>
</template>

<script>
export default {
  name: "PageErr"
};
</script>

<style scoped></style>
