import Vue from "vue";
import VueRouter from "vue-router";
// import PageTest from "@/pages/PageTest";
import PageErr from "@/pages/PageErr";
import PageProductTypesMgr from "@/pages/PageProductTypesMgr";
import PageMgrCenter from "@/pages/PageMgrCenter";
import PageProductMgr from "@/pages/PageProductMgr";
import PageProductEdit from "@/pages/PageProductEdit";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "PageTest",
  //   component: PageTest
  // },
  {
    path: "/",
    name: "PageMgrCenter",
    component: PageMgrCenter,
    meta: {
      // 页面标题title
      title: "店铺管理中心"
    }
  },
  {
    path: "/ProductTypesMgr",
    name: "ProductTypesMgr",
    component: PageProductTypesMgr,
    meta: {
      // 页面标题title
      title: "商品类型管理"
    }
  },
  {
    path: "/PageMgrCenter",
    name: "PageMgrCenter",
    component: PageMgrCenter,
    meta: {
      // 页面标题title
      title: "店铺管理中心"
    }
  },
  {
    path: "/PageProductMgr",
    name: "PageProductMgr",
    component: PageProductMgr,
    meta: {
      // 页面标题title
      title: "商品管理"
    }
  },
  {
    path: "/PageProductEdit",
    name: "PageProductEdit",
    component: PageProductEdit,
    meta: {
      // 页面标题title
      title: "商品编辑"
    }
  },
  {
    // 会匹配所有路径 放在最下面处理无法匹配的路由
    path: "*",
    name: "PageErr",
    component: PageErr
  }
];
const router = new VueRouter({
  // todo     'history'需要后台配置
  // mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
