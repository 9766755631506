import { render, staticRenderFns } from "./PageProductEdit.vue?vue&type=template&id=548f2129&scoped=true&"
import script from "./PageProductEdit.vue?vue&type=script&lang=js&"
export * from "./PageProductEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "548f2129",
  null
  
)

export default component.exports