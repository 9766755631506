import Vue from "vue";

let _loadingCount = 0;

export function showLoading() {
  if (_loadingCount === 0) {
    Vue.prototype.$bus.emit("showLoading", true);
  }
  _loadingCount++;
  // console.log("loading"+_loadingCount);
}

export function dismissLoading() {
  if (_loadingCount === 0) {
    return;
  }
  _loadingCount--;
  if (_loadingCount === 0) {
    Vue.prototype.$bus.emit("show-loading", false);
  }
  // console.log("dismissLoading"+_loadingCount);
}
